import getEnv from './env';
import { ActionArgs } from '@repo/shared-types';

export default function loadAdTool({ context }: ActionArgs): HTMLIFrameElement {
  const env = getEnv();
  const src = context.adToolSource

  const frame = env.document.createElement('iframe');
  frame.id = 'hybrid-ad-tool';
  frame.style.cssText = `
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    max-width: 600px;
    z-index: 1000000000;
    border: none;
    outline: none;
    box-shadow: 
      0 1px 1px rgba(0,0,0,0.11),
      0 2px 2px rgba(0,0,0,0.11),
      0 4px 4px rgba(0,0,0,0.11),
      0 6px 8px rgba(0,0,0,0.11),
      0 8px 16px rgba(0,0,0,0.11);
  `;

  frame.addEventListener('load', () => {
    const script = frame.contentDocument?.createElement('script');
    if (script) {
      script.src = src;
      frame.contentDocument?.body.appendChild(script);
    }
  });

  env.document.body.appendChild(frame);
  return frame
}
