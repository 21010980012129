import { getEnv } from '@repo/utils';
import { ThirdParty, ThirdPartyAPIMachineConfigs, Permutive } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';
import { PartialPick } from '@repo/utils';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.PERMUTIVE], 'thirdParty'> = {
  thirdParty: ThirdParty.PERMUTIVE,
  scriptLocation:
    'https://6093eccf-6734-4877-ac8b-83d6d0e27b46.edge.permutive.app/6093eccf-6734-4877-ac8b-83d6d0e27b46-web.js',
  methods: {
    loadScript: async (scriptLocation): Promise<void> => {
      if (document.querySelector(`script[src="${scriptLocation}"]`)) {
        return;
      }

      const env = getEnv();
      const apiMethods = [
        'addon',
        'identify',
        'track',
        'trigger',
        'query',
        'segment',
        'segments',
        'ready',
        'on',
        'once',
        'user',
        'consent',
      ];
      if (!env.permutive) {
        const permutiveObject: Permutive = {
          q: [],
          config: {
            consentRequired: true,
            environment: 'production',
            apiKey: '253158cc-875d-4ed4-a52d-e954eca49313',
          },
        };

        apiMethods.forEach(method => {
          permutiveObject[method] = (...args: unknown[]): void => {
            permutiveObject.q.push({ functionName: method, arguments: args });
          };
        });

        env.permutive = permutiveObject;
      }

      env.googletag = env.googletag || ({ cmd: [] } as unknown as googletag.Googletag);
      env.googletag.cmd.push(function () {
        if (!env.googletag?.pubads().getTargeting('permutive').length) {
          const g = env.localStorage.getItem('_pdfps');
          env.googletag?.pubads()?.setTargeting('permutive', g ? JSON.parse(g) : []);
        }
      });

      await loadScript(scriptLocation, 'permutive-script-app', { defer: true });
    },
  },
};
export default config;
