import { log } from '@repo/utils';
import {
  EnableFeatureApiEvent,
  SetExperimentIdApiEvent,
  DisableFeatureApiEvent,
  GptLoadedExternallyApiEvent,
  FEATURE,
  ActionArgs,
  BordeauxMachineContext,
  API_EMIT,
  Slotify_ON,
  Slotify_RECIEVE,
  Refresh_RECIEVE,
  SetRefreshPausedApiEvent,
  SetRefreshPausedEvent as SetExternalRefreshPausedEvent,
  DO,
  AnyBordeauxEvent,
  IF,
  DO_RECORD,
  BordeauxGuards,
  BordeauxActionsUnion,
  TO,
} from '@repo/shared-types';
import assign from './proxy/assign';
import GuardArgs from './proxy/guard-args.types';
import sendTo from './proxy/send-to';
import { and, EventObject, MetaObject, ProvidedActor, TransitionsConfig } from 'xstate';
import forwardEventData from './proxy/forward-event-data';

const apiEvents: TransitionsConfig<
  BordeauxMachineContext,
  AnyBordeauxEvent,
  ProvidedActor,
  BordeauxActionsUnion,
  BordeauxGuards,
  string,
  EventObject,
  MetaObject
> = {
  [API_EMIT.SET_ADTOOL_VERSION]: {
    actions: [forwardEventData(DO.set_adToolVersion), DO.decide_adToolSource],
  },
  [API_EMIT.SET_LOAD_GPT_EXTERNALLY]: {
    actions: forwardEventData(DO.set_loadGPTExternally),
  },
  [API_EMIT.GPT_LOADED_EXTERNALLY]: {
    actions: ({ context, event }: ActionArgs<GptLoadedExternallyApiEvent>): void => {
      context.loadGptExternallyPromise.resolve(event.data);
    },
  },
  [API_EMIT.SET_PREBID_ANALYTICS_ENABLED]: {
    actions: forwardEventData(DO.set_prebidAnalyticsEnabled),
  },
  [API_EMIT.SET_AUCTION_TIMEOUTS]: {
    actions: forwardEventData(DO.set_auctionTimeouts),
  },
  [API_EMIT.SET_AUTOMATIC_DYNAMIC]: {
    actions: forwardEventData(DO.set_automaticDynamic),
  },
  [API_EMIT.SET_COMPANION_BOUNDS]: {
    actions: forwardEventData(DO.set_overrideCompanionBounds),
  },
  [API_EMIT.SET_EXPERIMENT_ID]: [
    {
      guard: IF.hasExperimentId,
      actions: ({ context, event }: ActionArgs<SetExperimentIdApiEvent>): void => {
        log.warn(
          `Bordeaux experiment id has already been set to ${context.experimentId}, not setting ${event.data}`,
        );
      },
    },
    {
      actions: [
        forwardEventData(DO.set_experimentId),
        assign<SetExperimentIdApiEvent>({
          timing: ({ context }) => ({
            ...context.timing,
            enabled: true,
          }),
        }),
        forwardEventData(DO_RECORD.EXPERIMENT_ID),
      ],
    },
  ],
  [API_EMIT.SET_PAGE_CATEGORY]: {
    actions: forwardEventData(DO.set_pageCategory),
  },
  [API_EMIT.SET_PAGE_TEMPLATE]: {
    actions: forwardEventData(DO.set_pageTemplate),
  },
  [API_EMIT.SET_REFRESH_TIME]: {
    actions: forwardEventData(DO.set_refreshTime),
  },
  [API_EMIT.SET_TAKEOVER_INCREMENTAL_CAPS]: {
    actions: forwardEventData(DO.set_takeoverIncrementalCaps),
  },
  [API_EMIT.SET_TAKEOVER_INCREMENTAL_CHOOSER]: {
    actions: forwardEventData(DO.set_takeoverIncrementalChooser),
  },
  [API_EMIT.SET_ACTIVATION_DISTANCE]: {
    actions: forwardEventData(DO.set_activationDistance),
  },
  [API_EMIT.SET_AVOIDANCE_DISTANCE]: {
    actions: forwardEventData(DO.set_avoidanceDistance),
  },
  [API_EMIT.SET_THIRD_PARTY_API_CONFIG]: {
    actions: forwardEventData(DO.set_thirdPartyAPIConfigOverrides),
  },
  [API_EMIT.SET_PAGE_TARGETING]: {
    actions: forwardEventData(DO.updatePageTargeting),
  },
  [API_EMIT.ENABLE_FEATURE]: [
    {
      guard: forwardEventData(IF.featureEnabled),
      // Do nothing if feature is already enabled
    },
    {
      guard: ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
        event.data === FEATURE.ADS_REFRESH,
      actions: [
        forwardEventData(DO.feature_enable),
        sendTo<BordeauxMachineContext[TO.automaticRefresh], EnableFeatureApiEvent>(
          TO.automaticRefresh,
          {
            type: Refresh_RECIEVE.setFeatureEnabled,
            data: true,
          },
        ),
      ],
    },
    {
      guard: and([
        ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
          event.data === FEATURE.ADS_STANDARD,
        ({ context: { slotifyMode } }) => slotifyMode,
      ]),
      actions: [
        forwardEventData(DO.feature_enable),
        sendTo(TO.slotify, {
          type: Slotify_RECIEVE.standardAdsEnabled,
        }),
      ],
    },
    {
      guard: and([
        ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
          event.data === FEATURE.ADS_INCREMENTAL,
        ({ context: { slotifyMode } }) => slotifyMode,
      ]),
      actions: [
        forwardEventData(DO.feature_enable),
        sendTo(TO.slotify, {
          type: Slotify_RECIEVE.incrementalAdsEnabled,
        }),
      ],
    },
  ],
  [API_EMIT.DISABLE_FEATURE]: [
    {
      guard: IF.featuresInitialised,
      actions: ({ event }: ActionArgs<DisableFeatureApiEvent>): void => {
        log.warn(`Cannot disable the ${event.data} feature after Bordeaux has initialised.`);
      },
    },
    {
      guard: ({ event }: GuardArgs<DisableFeatureApiEvent>): boolean =>
        event.data === FEATURE.ADS_REFRESH,
      actions: [
        forwardEventData(DO.feature_disable),
        sendTo<BordeauxMachineContext[TO.automaticRefresh], DisableFeatureApiEvent>(
          TO.automaticRefresh,
          {
            type: Refresh_RECIEVE.setFeatureEnabled,
            data: false,
          },
        ),
      ],
    },
    {
      actions: forwardEventData(DO.feature_disable),
    },
  ],
  [API_EMIT.SET_REFRESH_PAUSED]: {
    actions: sendTo<BordeauxMachineContext[TO.automaticRefresh], SetRefreshPausedApiEvent>(
      TO.automaticRefresh,
      ({ event }): SetExternalRefreshPausedEvent => ({
        type: Refresh_RECIEVE.setRefreshPaused,
        data: event.data,
      }),
    ),
  },
  [API_EMIT.SET_FALLBACK_RESPONSES]: {
    actions: forwardEventData(DO.set_fallbackResponses),
  },
  [API_EMIT.REQUEST_HANDLE_DYNAMIC_SLOTS]: {
    guard: ({ context: { slotifyMode } }) => slotifyMode,
    actions: [
      sendTo(TO.slotify, {
        type: Slotify_ON.findNewDynamicSlots,
      }),
    ],
  },
  [API_EMIT.ADD_UNREFRESHABLE_NAMES]: {
    actions: forwardEventData(DO.addUnrefreshableNames),
  },
  [API_EMIT.DOCK_AD_TOOL]: {
    actions: [
      DO.openDockedTool,
      DO.closeUndockedTool,
      assign({
        unDockedTool: null,
      }),
    ],
  },
  [API_EMIT.UN_DOCK_AD_TOOL]: {
    actions: [
      DO.openUndockedTool,
      DO.closeDockedTool,
      assign({
        dockedTool: null,
      }),
    ],
  },
};

export default apiEvents;
