export enum ThirdPartyAPI_GO {
  start = 'THIRD_PARTY_API:STATE:START',
  load = 'THIRD_PARTY_API:STATE:LOAD',
  done = 'THIRD_PARTY_API:STATE:DONE',
}

export enum ThirdPartyAPI_DO {
  getConfig = 'THIRD_PARTY_API:ACTION:GET_CONFIG',
  getConsent = 'THIRD_PARTY_API:ACTION:GET_CONSENT',
  getScriptLocation = 'THIRD_PARTY_API:ACTION:GET_SCRIPT_LOCATION',

  markSuccess = 'THIRD_PARTY_API:ACTION:MARK_SUCCESS',

  sendRequestEvent = 'THIRD_PARTY_API:ACTION:SEND_REQUEST_EVENT',
  sendSuccessEvent = 'THIRD_PARTY_API:ACTION:SEND_SUCCESS_EVENT',
  sendFailureEvent = 'THIRD_PARTY_API:ACTION:SEND_FAILURE_EVENT',
}
export enum ThirdPartyAPI_IF {
  notEnabled = 'THIRD_PARTY_API:GUARD:NOT_ENABLED',
  noConsent = 'THIRD_PARTY_API:GUARD:NO_CONSENT',
  noScript = 'THIRD_PARTY_API:GUARD:NO_SCRIPT',
}
export enum ThirdPartyAPI_SPAWN {
  loadScript = 'THIRD_PARTY_API:ACTOR:LOAD_SCRIPT'
}
export enum ThirdPartyAPI_TO {
  loadScript = 'LOAD_SCRIPT'
}

export enum ThirdParty {
  AD_SERVER = 'adServer',
  AM_CLIO = 'amClio',
  AMAZON = 'amazon',
  GPT = 'gpt',
  GLOBAL = 'global',
  IAS = 'ias',
  PERMUTIVE = 'permutive',
  INDEX_EXCHANGE = 'indexExchange',
  LIVE_RAMP = 'liveRamp',
  PUBMATIC = 'pubmatic',
  PREBID = 'prebid',
  EUID = 'euid',
  PUBX = 'pubx',
  TMT = 'mediaTrust',
  LIVE_INTENT = 'liveIntent',
  UID2 = 'uid2',
}