import {
  AdManager_SPAWN,
  AnyAdManagerEvent,
  Googletag_ON,
  GoogletagSlotImpressionViewableEvent,
  GoogletagSlotOnLoadEvent,
  GoogletagSlotRenderEndedEvent,
  GoogletagSlotVisibilityChangedEvent,
  EMPTY_OUTPUT,
} from '@repo/shared-types';
import { getEnv } from '@repo/utils';
import { AnyEventObject, fromCallback, fromPromise } from 'xstate';

const googletagListener = fromCallback<
  AnyEventObject,
  { googletag: googletag.Googletag },
  AnyAdManagerEvent
>(({ input: { googletag }, sendBack }) => {
  const handleSlotVisibilityChanged = (
    event: googletag.events.SlotVisibilityChangedEvent,
  ): void => {
    const adId = event.slot.getSlotElementId();
    sendBack({
      type: Googletag_ON.visibilityChanged,
      data: { adId, inViewPercentage: event.inViewPercentage },
    } as GoogletagSlotVisibilityChangedEvent);
  };

  const handleSlotRenderEnded = (event: googletag.events.SlotRenderEndedEvent): void => {
    const adId = event.slot.getSlotElementId();
    const gptOutput = event.isEmpty
      ? EMPTY_OUTPUT
      : {
          isEmpty: event.isEmpty,
          size: event.size,
          advertiser: event.advertiserId || -1,
          campaign: event.campaignId || -1,
          lineItem: event.lineItemId || -1,
          creative: event.creativeId || -1,
          creativeTemplate: '',
          gptSlot: event.slot,
        };
    sendBack({
      type: Googletag_ON.renderEnded,
      data: { adId, gptOutput },
    } as GoogletagSlotRenderEndedEvent);
  };

  const handleSlotOnLoad = (event: googletag.events.SlotOnloadEvent): void => {
    const adId = event.slot.getSlotElementId();
    sendBack({
      type: Googletag_ON.onLoad,
      data: { adId },
    } as GoogletagSlotOnLoadEvent);
  };

  const handleImpressionViewable = (event: googletag.events.ImpressionViewableEvent): void => {
    const adId = event.slot.getSlotElementId();
    sendBack({
      type: Googletag_ON.impressionViewable,
      data: { adId },
    } as GoogletagSlotImpressionViewableEvent);
  };

  googletag.pubads().addEventListener('slotVisibilityChanged', handleSlotVisibilityChanged);
  googletag.pubads().addEventListener('slotRenderEnded', handleSlotRenderEnded);
  googletag.pubads().addEventListener('slotOnload', handleSlotOnLoad);
  googletag.pubads().addEventListener('impressionViewable', handleImpressionViewable);
});

const waitForGoogleTag = fromPromise<void, void>(async () => {
  const env = getEnv();
  const { googletag } = env;
  if (!googletag) return;
  if (!googletag.cmd) return;

  return new Promise(resolve => {
    googletag.cmd.push(resolve);
  });
});

const adManagerActors = {
  [AdManager_SPAWN.waitForGoogleTag]: waitForGoogleTag,
  [AdManager_SPAWN.googleTagListener]: googletagListener,
};

export default adManagerActors;
