import { z } from 'zod';
import {
  AdUnitCategory,
  AdUnitMode,
  AdUnitPosition,
  AdUnitSizeTuple,
  ALL_COUNTRIES_ENABLED, 
  // truthyString,
  // stringyList, 
  // lowercaseStringyList,
} from '../../../index';

import truthyString from '../generic/truthy-string';
import lowercaseStringyList from '../generic/lowercase-stringy-list';
import stringyList from '../generic/stringy-list';

export const adUnitCategorySchema = z.union([
  z.literal(AdUnitCategory.MPU),
  z.literal(AdUnitCategory.DMPU),
  z.literal(AdUnitCategory.LEADERBOARD),
  z.literal(AdUnitCategory.SPONSORED_POST),
  z.literal(AdUnitCategory.SPONSORED_BRAND),
  z.literal(AdUnitCategory.INFINITE_SCROLL),
]);

export const adUnitTypeSchema = z.union([
  z.literal('standard'),
  z.literal('incremental'),
  z.literal('sponsored'),
  z.literal('native'),
  z.literal(AdUnitMode.SKYSCRAPER),
  z.literal(AdUnitMode.ANCHORED),
  z.literal(AdUnitMode.OOP),
  z.literal(AdUnitMode.INTERSTITIAL),
]);

export const adUnitPropertiesSchema = z.object({
  category: adUnitCategorySchema.optional(),
  refresh: truthyString,
  position: z.union([z.literal(AdUnitPosition.LEFT), z.literal(AdUnitPosition.RIGHT)]).optional(),
  centerVertically: truthyString,
  topPosition: z.number().default(0),
  disabledCountries: lowercaseStringyList.default([]),
  enabledCountries: lowercaseStringyList
    .or(z.literal(ALL_COUNTRIES_ENABLED))
    .default(ALL_COUNTRIES_ENABLED),
  addFluidSize: truthyString,
  closeButton: truthyString,
  fluid: truthyString,
  rules: z.string().optional(),
  refreshDisable: truthyString,
  refreshDisabledSizes: stringyList.transform(sizes =>
    sizes.map((size): [number, number] => {
      const [width, height] = size.split('x');
      return [parseInt(width, 10), parseInt(height, 10)];
    }),
  ),
  ignoreAvoidance: truthyString,
  inTakeover: truthyString,
  takeoverIncremental: truthyString,
  inRoadblock: truthyString,
  roadblockIncremental: truthyString,
});

export const adUnitSchema = z.object({
  name: z.string(),
  sizes: z
    .array(
      z
        .object({
          w: z.number(),
          h: z.number(),
        })
        .transform((size): AdUnitSizeTuple => [size.w, size.h]),
    )
    .default([{ w: 1, h: 1 }]),
  type: adUnitTypeSchema,
  requestOrder: z.number().default(0),
  slotAffinity: z.string().optional(),
  lazyload: z.boolean().default(false),
  targeting: z.record(z.array(z.string()).or(z.string())).default({}),
  properties: z.preprocess(input => input ?? {}, adUnitPropertiesSchema.default({})),
});
