import { API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

let refreshPaused = false;

export default (service: ApiMachineEventService): boolean => {
  refreshPaused = !refreshPaused;
  service.sendEvent({
    type: API_EMIT.SET_REFRESH_PAUSED,
    data: refreshPaused,
  });
  return refreshPaused;
};
